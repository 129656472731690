<template>
  <div class="content-searchbar-transation-account">
    <div class="content-searchbar-textfield">
      <v-skeleton-loader type="button"></v-skeleton-loader>
    </div>
    <v-spacer></v-spacer>
    <div class="content-filter">
      <v-skeleton-loader type="button"></v-skeleton-loader>
    </div>
    <div class="content-btn-add-transation">
      <v-skeleton-loader type="button"></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.content-searchbar-transation-account {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 15px;
}
.content-searchbar-textfield {
  width: 400px;
}
.content-filter {
  margin-left: 15px;
}
.content-btn-add-transation {
  width: 200px;
  margin-left: 15px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-filters-Account {
    display: block;
  }
  .content-searchbar-transation-account {
    display: block;
    width: 100%;
  }
  .content-searchbar-textfield {
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter {
    margin-left: 0px;
    margin-bottom: 15px;
  }
  .content-btn-add-transation {
    width: 100%;
    margin-left: 0px;
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .content-filters-Account {
    display: block;
  }
  .content-searchbar-transation-account {
    display: block;
    width: 100%;
  }
  .content-searchbar-textfield {
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter {
    margin-left: 0px;
    margin-bottom: 15px;
  }
  .content-btn-add-transation {
    width: 100%;
    margin-left: 0px;
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>