import { render, staticRenderFns } from "./SkeletonSearchbarTransationComponent.vue?vue&type=template&id=02f6a83c&scoped=true&"
import script from "./SkeletonSearchbarTransationComponent.vue?vue&type=script&lang=js&"
export * from "./SkeletonSearchbarTransationComponent.vue?vue&type=script&lang=js&"
import style0 from "./SkeletonSearchbarTransationComponent.vue?vue&type=style&index=0&id=02f6a83c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f6a83c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VSkeletonLoader,VSpacer})
